body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  background-color: #222;
  color: #f0f8ff;
}
ul.nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  top: 0;
  width: 100%;
  height: 5vh;
  li {
    float: left;
    a {
      display: block;
      color: white;
      text-align: center;
      padding: 1.5vh 16px;
      font-size: 1.8vh;
      text-decoration: none;
      &:hover:not(.active) {
        background-color: #222;
      }
      &.active {
        background-color: #540488;
      }
    }
  }
}
div.about {
  width: fit-content;
  height: fit-content;
  margin: auto;  
  display: flex;
  justify-content: center;

  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;

  .about-text, img {
    width: fit-content;
    vertical-align:middle;
    display: inline-block;
  }
  .about-text {
    margin: auto 0;
    padding-left: 2.5rem;
    .trans {
      color: transparent !important;
      font-weight: bold; background-size: 300%;
      background-clip: text;
      animation: shine 10s linear infinite;
      background-image: linear-gradient(-60deg, rgba(91, 206, 250) 20%, rgba(245, 169, 184) 20%, 40%, rgba(255, 255, 255) 40%, 60%, rgba(245, 169, 184) 60%, 80%, rgba(91, 206, 250) 80%);
    }
  }
}
@keyframes shine { to { background-position: 300% center;}}
@media screen and (orientation:portrait) { 
  div.about {
    flex-direction: column;
  }
 }